<template>
  <div>
    <v-dialog
      v-model="isLoading"
      dark
      persistent
      width="300"
    >
      <v-card
        color="primary"
        width="300"
      >
        <v-card-text class="pt-3">
          <p class="mb-2">
            {{ $t('pleaseWait') }}
          </p>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <GoogleContactsSelection
      :contacts="contacts"
      :import-contacts-from-tour-guide="importContactsFromTourGuide"
      @moveToGuestList="moveToGuestList"
    ></GoogleContactsSelection>

    <v-btn
      :class="!importContactsFromTourGuide? 'mb-2 px-2':'justify-center align-center rounded-lg my-2'"
      dir="auto"
      :block="!importContactsFromTourGuide ? false : true "
      :text="!importContactsFromTourGuide ? true : false "
      :outlined="!importContactsFromTourGuide ? false : true "
      :x-large="!importContactsFromTourGuide ? false : true "
      @click.stop="getGoogleContacts"
    >
      <img
        src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
        alt="Google logo"
        class="google-logo"
      >
      <span class="mx-2">{{ $t('ImportContacts') }}</span>
    </v-btn>
  </div>
</template>

<script>
import { i18n } from '@/plugins/i18n/index'
import store from '@/store'
import { mdiAccountMultiplePlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Vue from 'vue'
import { useToast } from 'vue-toastification/composition'
import GoogleContactsSelection from './GoogleContactsSelection.vue'

export default {
  components: {
    GoogleContactsSelection,
  },
  props: ['importContactsFromTourGuide'],
  setup(props, { emit }) {
    const toast = useToast()
    const isLoading = ref(false)
    const showToast = message => toast.error(i18n.t(message))
    const contacts = ref([])

    const moveToGuestList = () => {
      emit('moveToGuestList')
    }

    const onGapiLoad = async () => {
      gapi.load('client', initializeGapiClient)
    }

    const loadGapi = async () => {
      const script = document.createElement('script')
      script.src = 'https://apis.google.com/js/platform.js'
      script.onload = () => {
        onGapiLoad()
      }
      document.body.appendChild(script)
    }

    loadGapi()

    const initializeGapiClient = async () => {
      await gapi.client.init({
        clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        scope: 'https://www.googleapis.com/auth/contacts.readonly',
        plugin_name: 'contacts',
      })
    }

    const filterPrimaryContacts = data => {
      const currentGuests = store.getters['inviteList/getUserListTable']
      const addedPhoneNumbers = new Set()

      const unfilteredContacts = Object.values(data).map(item => {
        if (!item?.names || !item?.phoneNumbers) {
          return
        }
        const primaryName = item.names.find(name => name.metadata.primary)
        const primaryPhone = item.phoneNumbers.find(phone => phone.metadata.primary)

        // eslint-disable-next-line consistent-return
        return {
          fullName: primaryName ? primaryName.displayName : '',
          phoneNumber: primaryPhone ? primaryPhone.canonicalForm : '',
        }
      })

      return unfilteredContacts.filter(contact => {
        if (
          contact?.fullName &&
          contact?.phoneNumber &&
          !currentGuests.find(guest => guest.phoneNumber === contact.phoneNumber) &&
          !addedPhoneNumbers.has(contact.phoneNumber)
        ) {
          addedPhoneNumbers.add(contact.phoneNumber)

          return true
        }

        return false
      })
    }

    const getGoogleContacts = async () => {
      emit('closeMenu')
      try {
        isLoading.value = true
        await gapi.auth2.getAuthInstance().signIn()
        const apiUrl =
          'https://people.googleapis.com/v1/people/me/connections?personFields=names,phoneNumbers,emailAddresses'
        let pageToken = ''
        let allContacts = []

        do {
          const response = await gapi.client.request({
            path: `${apiUrl}&pageToken=${pageToken}`,
          })

          const contacts = response.result.connections

          if (contacts) {
            allContacts = [...allContacts, ...contacts]
          }

          pageToken = response.result.nextPageToken
        } while (pageToken)

        contacts.value = filterPrimaryContacts(allContacts)

        // Track the successful import of contacts from Google
        Vue.prototype.$gtag.event('Import_Contacts', {
          event_category: 'Contact Management',
          event_label: 'Google Contacts',
        })
        isLoading.value = false
      } catch (error) {
        isLoading.value = false
        showToast(i18n.t('errorFetchingGoogleContacts'))
      }
    }

    return {
      contacts,
      getGoogleContacts,
      isLoading,
      moveToGuestList,

      icons: {
        mdiAccountMultiplePlus,
      },
    }
  },
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.google-logo {
  width: 18px; /* Logo size */
  height: 18px;
}
</style>
