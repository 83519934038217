var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"fullscreen":"","overlay":false,"transition":"dialog-bottom-transition"},model:{value:(_vm.showGuestErrorFromExcelPopup),callback:function ($$v) {_vm.showGuestErrorFromExcelPopup=$$v},expression:"showGuestErrorFromExcelPopup"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[(_vm.type === 'excel')?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('UploadExcelFile'))+" ")]):_vm._e(),(_vm.type === 'google')?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('ImportContacts'))+" ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","plain":""},on:{"click":_vm.closeErrorsFromExcel}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-close ")])],1)],1),_c('v-card',{staticClass:"mt-3",attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('recognizeErrorsFromExcel'))+" ")]),_c('v-card-subtitle',[_c('span',[_vm._v(" "+_vm._s(_vm.$t('moreInfoAboutErrors'))+" ")]),_c('span',[_c('v-icon',{staticClass:"px-1",attrs:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAlertCircle)+" ")])],1),(_vm.type === 'excel')?_c('div',[_vm._v(" "+_vm._s(_vm.$t('fixTheExcelErrors'))+" ")]):_vm._e()]),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.inviteesWithErrors,"header-props":{ sortByText: _vm.$t('SortBy') },"no-data-text":_vm.$t('NoData'),"hide-default-footer":"","items-per-page":_vm.itemsPerPage,"page":_vm.page},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header.fullName",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('fullName'))+" ")])]},proxy:true},{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"center"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"v-avatar-light-bg secondary--text",attrs:{"color":"secondary","size":"32"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.fullName)))])]),_c('div',{staticClass:"d-flex flex-column ms-2"},[_c('span',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"},[_vm._v(" "+_vm._s(item.fullName)+" ")])])],1),(_vm.hasError(item, 'fullName'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"px-1",attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiAlertCircle)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('MinField', {num: '2'})))])]):_vm._e()],1)]}},{key:"header.phoneNumber",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('phoneNumber'))+" ")])]},proxy:true},{key:"item.phoneNumber",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"text-capitalize text--primary"},[_vm._v(" "+_vm._s(_vm.formatPhoneNumber(item.phoneNumber))+" ")]),(_vm.hasError(item, 'phoneNumber'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"px-1",attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiAlertCircle)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('invalidPhoneNumber')))])]):_vm._e(),(_vm.hasError(item, 'phoneNumberExist'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"px-1",attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiAlertCircle)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('PhoneNumberDuplicate')))])]):_vm._e()],1)]}},(_vm.type === 'excel')?{key:"header.countOfInvites",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('countOfInvites'))+" ")])]},proxy:true}:null,(_vm.type === 'excel')?{key:"item.countOfInvites",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"text-capitalize text--primary"},[_vm._v(" "+_vm._s(item.countOfInvites)+" ")]),(_vm.hasError(item, 'countOfInvites'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"px-1",attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiAlertCircle)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('countOfInvitesBetween1to10')))])]):_vm._e()],1)]}}:null],null,true)}),_c('div',{staticClass:"text-center pt-2 pb-5"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }