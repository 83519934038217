<template>
  <div>
    <v-dialog
      v-model="showGuestErrorFromExcelPopup"
      fullscreen
      :overlay="false"
      transition="dialog-bottom-transition"
    >
      <!-- Dialog Content -->
      <v-card>
        <!-- Toolbar -->
        <v-toolbar
          color="primary"
          dark
        >
          <v-toolbar-title v-if="type === 'excel'">
            {{ $t('UploadExcelFile') }}
          </v-toolbar-title>
          <v-toolbar-title v-if="type === 'google'">
            {{ $t('ImportContacts') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            plain
            @click="closeErrorsFromExcel"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card
          class="mt-3"
          flat
        >
          <v-card-title>
            {{ $t('recognizeErrorsFromExcel') }}
          </v-card-title>

          <v-card-subtitle>
            <span>
              {{ $t('moreInfoAboutErrors') }}
            </span>
            <span>
              <v-icon
                color="red"
                class="px-1"
              >
                {{ icons.mdiAlertCircle }}
              </v-icon>
            </span>
            <div v-if="type === 'excel'">
              {{ $t('fixTheExcelErrors') }}
            </div>
          </v-card-subtitle>
          <v-data-table
            :headers="tableColumns"
            :items="inviteesWithErrors"
            :header-props="{ sortByText: $t('SortBy') }"
            :no-data-text="$t('NoData')"
            hide-default-footer
            :items-per-page="itemsPerPage"
            :page.sync="page"
            @page-count="pageCount = $event"
          >
            <!-- name -->
            <template #[`header.fullName`]>
              <span> {{ $t('fullName') }} </span>
            </template>

            <template #[`item.fullName`]="{item}">
              <v-row
                align="center"
              >
                <div
                  class="d-flex align-center"
                >
                  <v-avatar
                    class="v-avatar-light-bg secondary--text"
                    color="secondary"
                    size="32"
                  >
                    <span
                      class="font-weight-medium"
                    >{{ avatarText(item.fullName) }}</span>
                  </v-avatar>

                  <div class="d-flex flex-column ms-2">
                    <span
                      class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                    >
                      {{ item.fullName }}
                    </span>
                  </div>
                </div>
                <v-tooltip
                  v-if="hasError(item, 'fullName')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon

                      color="red"
                      v-bind="attrs"
                      class="px-1"
                      v-on="on"
                    >
                      {{ icons.mdiAlertCircle }}
                    </v-icon>
                  </template>
                  <span>{{ $t('MinField', {num: '2'}) }}</span>
                </v-tooltip>
              </v-row>
            </template>

            <!-- phone number -->
            <template #[`header.phoneNumber`]>
              <span> {{ $t('phoneNumber') }} </span>
            </template>

            <template #[`item.phoneNumber`]="{item}">
              <v-row
                align="center"
                justify="center"
              >
                <div class="text-capitalize text--primary">
                  {{ formatPhoneNumber(item.phoneNumber) }}
                </div>
                <v-tooltip
                  v-if="hasError(item, 'phoneNumber')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon

                      color="red"
                      v-bind="attrs"
                      class="px-1"
                      v-on="on"
                    >
                      {{ icons.mdiAlertCircle }}
                    </v-icon>
                  </template>
                  <span>{{ $t('invalidPhoneNumber') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="hasError(item, 'phoneNumberExist')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon

                      color="red"
                      v-bind="attrs"
                      class="px-1"
                      v-on="on"
                    >
                      {{ icons.mdiAlertCircle }}
                    </v-icon>
                  </template>
                  <span>{{ $t('PhoneNumberDuplicate') }}</span>
                </v-tooltip>
              </v-row>
            </template>

            <!-- number of invitee -->
            <template
              v-if="type === 'excel'"
              #[`header.countOfInvites`]
            >
              <span> {{ $t('countOfInvites') }} </span>
            </template>

            <template
              v-if="type === 'excel'"
              #[`item.countOfInvites`]="{item}"
            >
              <v-row
                align="center"
                justify="center"
              >
                <div class="text-capitalize text--primary">
                  {{ item.countOfInvites }}
                </div>

                <v-tooltip
                  v-if="hasError(item, 'countOfInvites')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="red"
                      v-bind="attrs"
                      class="px-1"
                      v-on="on"
                    >
                      {{ icons.mdiAlertCircle }}
                    </v-icon>
                  </template>
                  <span>{{ $t('countOfInvitesBetween1to10') }}</span>
                </v-tooltip>
              </v-row>
            </template>
          </v-data-table>

          <div class="text-center pt-2 pb-5">
            <v-pagination
              v-model="page"
              :length="pageCount"
            ></v-pagination>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { i18n } from '@/plugins/i18n/index'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import { mdiAlertCircle } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'

export default {
  props: {
    inviteesWithErrors: Array,
    type: String,
  },
  setup(props, { emit }) {
    const showGuestErrorFromExcelPopup = computed({
      get: () => store.getters['inviteList/getShowGuestErrorFromExcelPopup'],
      set: value => store.commit('inviteList/setShowGuestErrorFromExcelPopup', value),
    })

    const hasError = (item, errorType) => item.errors.includes(errorType)

    const closeErrorsFromExcel = () => {
      showGuestErrorFromExcelPopup.value = false
      store.dispatch('inviteList/fetchGuests', { payload: { shouldLoad: true } })

      emit('moveToGuestList')
    }

    const formatPhoneNumber = phoneNumber => {
      // Check for falsy values including undefined, null, and empty string
      if (!phoneNumber) {
        return ''
      }

      // Replace +972 prefix with 0
      if (phoneNumber.slice(0, 4) === '+972') {
        const formatted = `0${phoneNumber.slice(4, 15)}`

        return formatted
      }

      // Return original phoneNumber if none of the above conditions met
      return phoneNumber
    }

    // Pagination
    const page = ref(1)
    const pageCount = ref(0)
    const itemsPerPage = ref(10)

    const tableColumns = ref([
      {
        text: i18n.t('fullName'),
        value: 'fullName',
        align: 'center',
        width: '200',
      },
      {
        text: i18n.t('phoneNumber'),
        value: 'phoneNumber',
        sortable: false,
        align: 'center',
        width: 'auto',
      },
      props.type === 'excel'
        ? {
            text: i18n.t('countOfInvites'),
            value: 'countOfInvites',
            sortable: false,
            align: 'center',
            width: '50',
          }
        : '',
    ])

    return {
      hasError,
      tableColumns,
      page,
      pageCount,
      itemsPerPage,
      avatarText,
      showGuestErrorFromExcelPopup,
      closeErrorsFromExcel,
      formatPhoneNumber,
      icons: {
        mdiAlertCircle,
      },
    }
  },
}
</script>
