<template>
  <div style="padding: 35px; padding-top: 15px">
    <transition name="tour-guide-fade">
      <div v-if="showTitles">
        <div class="text-2xl py-5 text-2xl font-weight-bold">
          <span>
            {{ $t('we_do_almost_all_job') }}
          </span>
          <span class="black--text">
            {{ $t('you_need_only_add_guests') }}
          </span>
        </div>
      </div>
    </transition>

    <div class="py-5 text-center mt-2">
      <!-- import guests from excel file -->
      <transition name="tour-guide-fade">
        <div v-if="showTitles">
          <ImportFromExcel
            :import-contacts-from-tour-guide="true"
            @moveToGuestList="moveToGuestList"
          ></ImportFromExcel>
        </div>
      </transition>

      <!-- import guests from google account -->
      <transition name="tour-guide-fade">
        <div v-if="showTitles">
          <GoogleContacts
            :import-contacts-from-tour-guide="true"
            @moveToGuestList="moveToGuestList"
          ></GoogleContacts>
        </div>
      </transition>

      <!-- add guests manually -->
      <transition name="tour-guide-fade">
        <v-btn
          v-if="showTitles"
          x-large
          block
          outlined
          elevation="8"
          class="justify-center align-center rounded-lg my-2 pa-0"
          @click="moveToGuestList"
        >
          <v-icon
            color="teal"
            size="30"
            right
          >
            {{ icons.mdiGestureTap }}
          </v-icon>
          <span class="mx-2">
            {{ $t('AddManually') }}
          </span>
        </v-btn>
      </transition>
      <div class="text-center pt-1">
        <!-- add guests later -->
        <transition name="tour-guide-fade">
          <a
            v-if="showTitles"
            style="color: #b9afc6; text-decoration: underline;"
            text
            @click="moveToGuestList"
          >
            {{ $t('add_guests_later') }}
          </a>
        </transition>
      </div>
    </div>

    <transition name="tour-guide-image-fade">
      <div
        v-if="showTitles"
        class="d-flex align-center justify-center my-5"
      >
        <PreloadedImage
          :src="require('@/assets/images/tour-guide/contacts-book.png')"
          style="opacity: 0.4; margin-top: 50px;"
          height="200"
          width="200"
          contain
        ></PreloadedImage>
      </div>
    </transition>
  </div>
</template>

<script>
import GoogleContacts from '@/components/GuestManagement/ImportInvitees/GoogleContacts.vue'
import ImportFromExcel from '@/components/GuestManagement/ImportInvitees/ImportFromExcel.vue'
import router from '@/router'
import store from '@/store'
import { mdiGestureTap } from '@mdi/js'
import { watchEffect } from '@vue/composition-api'
import { useTourGuideTitleAnimation } from '../useTourGuideTitleAnimation'

export default {
  name: 'Guests',
  components: { GoogleContacts, ImportFromExcel },
  props: {
    currentStep: {
      required: true,
    },
    stepIndex: {
      required: true,
    },
  },
  setup(props) {
    const { showTitles } = useTourGuideTitleAnimation(props)

    const moveToGuestList = () => {
      store.commit('setTourGuide', { value: false, type: '' })
    }

    watchEffect(() => {
      if (router.currentRoute.name === 'apps-user-list') return

      router.push({ name: 'apps-user-list' })
    })

    return {
      moveToGuestList,
      showTitles,

      icons: {
        mdiGestureTap,
      },
    }
  },
}
</script>
